import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { withSwal } from "react-sweetalert2";
import "../../../App.css";
import "../../../assets/css/AdminStyles.css";
import "../../../assets/css/fontStyles.css";

import axios from "axios";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { FaCalendarCheck, FaCalendarDay } from "react-icons/fa6";
import { MdMiscellaneousServices } from "react-icons/md";
import { scrollToTop } from "../../../helper/HelperFunctions";
import MasterLayout from "../master/MasterLayout";

const Dashboard = ({ swal }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loggedInAdmin, setLoggedInAdmin] = useState();
  const [totalServices, setTotalServices] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [upcomingAppointments, setUpcomingAppointments] = useState(0);
  const [completedAppointments, setCompletedAppointments] = useState(0);
  const [showEmail, setShowEmail] = useState("");
  const [showPhone, setShowPhone] = useState("");
  const [showCurrentEmail, setShowCurrentEmail] = useState("");
  const [showCurrentPhone, setShowCurrentPhone] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [access_token, setAccessToken] = useState(null);
  const [emailPhoneUpdateLoading, setEmailPhoneUpdateLoading] = useState(false);

  const getDashboardStats = (accessToken) => {
    axios
      .get(
        "https://admin.cornwallsecuritygroup.com/api/dashboard/dashboardStats",
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((resp) => {
        setTotalServices(resp.data.totalServices);
        setTotalContacts(resp.data.totalContacts);
        setUpcomingAppointments(resp.data.totalUpcomingAppointments);
        setCompletedAppointments(resp.data.totalCompletedAppointments);
        setShowCurrentEmail(resp.data.show_email);
        setShowCurrentPhone(resp.data.show_phone);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePhoneAndEmail = (accessToken) => {
    const formData = new FormData();
    formData.append("email", showEmail);
    formData.append("phone", showPhone);

    axios
      .post(
        "https://admin.cornwallsecuritygroup.com/api/dashboard/updateShowDetails",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data", //the token is a variable which holds the token
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        setShowEmail("");
        setShowPhone("");
        setEmailPhoneUpdateLoading(false);
        swal.fire({
          title: "Success",
          text: "Email and Phone Updated successfully.",
          icon: "success",
        });
        getDashboardStats(accessToken);
      })
      .catch((err) => {
        setErrorMessages(err.response.data.error);
        setLoading(false);
      });
  };

  useEffect(() => {
    scrollToTop();
    setTimeout(() => {
      if (localStorage.getItem("admin") == null) {
        navigate("/");
      } else {
        setLoggedInAdmin(JSON.parse(localStorage.getItem("admin")));
        const admin = JSON.parse(localStorage.getItem("admin"));
        getDashboardStats(admin.access_token);
        setAccessToken(admin.access_token);
        setLoading(false);
      }
    }, 1000);
  }, []);

  return loading ? (
    <Row className="d-flex justify-content-lg-center">
      <Spinner style={{ width: "50px", height: "50px", marginTop: "20%" }} />
    </Row>
  ) : (
    <MasterLayout>
      <Container className="m-0 p-0 " fluid>
        <h3 className="poppins-bold Heading-Text mt-5">Dashboard</h3>

        <Row className="px-2">
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">Total Services</h6>
                <h5 className="poppins-semiBold mt-4">{totalServices}</h5>
              </Container>
              <MdMiscellaneousServices size={35} className="Heading-Text" />
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">Contacts</h6>
                <h5 className="poppins-semiBold mt-4">{totalContacts}</h5>
              </Container>
              <BiSolidMessageSquareDetail size={20} className="Heading-Text" />
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">
                  Completed Appointments
                </h6>
                <h5 className="poppins-semiBold mt-2">
                  {completedAppointments}
                </h5>
              </Container>
              <FaCalendarCheck size={20} className="Heading-Text" />
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">
                  Upcoming Appointments
                </h6>
                <h5 className="poppins-semiBold mt-2">
                  {upcomingAppointments}
                </h5>
              </Container>
              <FaCalendarDay size={20} className="Heading-Text" />
            </Card>
          </Col>
        </Row>

        <Row className="px-2">
          <hr style={{ marginTop: 50, marginBottom: 50 }}></hr>
          <h3 className="poppins-bold Heading-Text mt-5">
            EMAIL AND PHONE UPDATE FORM
          </h3>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Text className="poppins-bold">Website New Email </Form.Text>
              <Form.Control
                className="poppins-regular"
                type="text"
                size="lg"
                value={showEmail}
                onChange={(e) => setShowEmail(e.target.value)}
              />
              <Form.Text className="poppins-bold text-primary">
                Current Email ({showCurrentEmail})
              </Form.Text>
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Text className="poppins-bold">Website New Phone</Form.Text>
              <Form.Control
                className="poppins-regular"
                type="text"
                size="lg"
                value={showPhone}
                onChange={(e) => setShowPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Text className="poppins-bold text-primary">
              Current Phone ({showCurrentPhone})
            </Form.Text>
          </Col>
          <Col lg={12} md={12} sm={12} className="py-3">
            <Button
              onClick={() => {
                // if (showEmail == "") {
                //   setErrorMessages("Email is required");
                // } else if (showPhone == "") {
                //   setErrorMessages("Phone is required. If You don't want to update, Typ");
                // } else {
                setEmailPhoneUpdateLoading(true);
                updatePhoneAndEmail(access_token);
                // }
              }}
              type="submit"
              variant="outline-dark"
              size="md"
              className="poppins-semiBold  mt-4 mx-3"
              style={{ width: "90%" }}
            >
              {emailPhoneUpdateLoading ? (
                <Spinner style={{ width: "20px", height: "20px" }} />
              ) : (
                "Update Details"
              )}
            </Button>
          </Col>
          {errorMessages != "" ? (
            <ToastContainer
              position="top-end"
              className="p-3"
              style={{ zIndex: 1 }}
            >
              <Toast
                bg="danger"
                onClose={() => {
                  setErrorMessages("");
                }}
                show={errorMessages == "" ? false : true}
                animation={true}
              >
                <Toast.Header>
                  <strong className="me-auto poppins-bold">IMPORTANT</strong>
                </Toast.Header>
                <Toast.Body className="poppins-regular text-light">
                  {errorMessages}
                </Toast.Body>
              </Toast>
            </ToastContainer>
          ) : null}
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default withSwal(Dashboard);
