import React, { useEffect,useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import securitylogo from "../../assets/images/white-logo.png";
import { Link } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { FaAnglesRight } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import axios from "axios";

const Footer = () => {

  const [showEmail, setShowEmail] = useState("");
  const [showPhone, setShowPhone] = useState("");
  const [phoneForDialer, setPhoneForDialer] = useState("");
  const [emailForDialer, setEmailForDialer] = useState("");

  const showPhoneAndEmail = () => {
    axios({
      method: "get",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/showEmailAndPhone`,
    })
      .then((resp) => {
        console.log(resp.data);
        setShowEmail(resp.data.showEmail);
        setShowPhone(resp.data.showPhone);
        setPhoneForDialer(`tel:${resp.data.showPhone}`);
        setEmailForDialer(`mailto:${resp.data.showEmail}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(()=>{
    showPhoneAndEmail();
  },[])
  return (
    <Container fluid className="m-0 p-0 bg-blue">
      <Row className="m-0 py-5 content-container">
        <Col className="mb-4">
          <Image
            className="footer-image"
            alt="Logo"
            src={securitylogo}
            onMouseOver={() => {}}
            onMouseOut={() => {}}
          />
          <p className="link-small-text txt-white poppins-regular">License No. B#1800024</p>
          <p className="link-small-text txt-white poppins-regular">
            Your World,<span className="txt-yellow"> Protected</span> by a Name
            You Trust – <span className="txt-yellow">Cornwall Security</span>
          </p>
        </Col>
        <Col lg={2}></Col>
        <Col>
          <h5 className="txt-white poppins-semiBold mb-4">Quick Links</h5>
          <Stack gap={"3"} className="poppins-regular">
            <Link className="footer-link" to="/">
              <FaAnglesRight className={"txt-yellow me-2"} size={12} />
              Home
            </Link>
            <Link className="footer-link" to="/about-us">
              <FaAnglesRight className={"txt-yellow me-2"} size={12} />
              About Us
            </Link>
            <Link className="footer-link" to="/services">
              <FaAnglesRight className={"txt-yellow me-2"} size={12} />
              Services
            </Link>
            <Link className="footer-link" to="/certified-guard">
              <FaAnglesRight className={"txt-yellow me-2"} size={12} />
              Certified Guards
            </Link>
            <Link className="footer-link" to="/contact-us">
              <FaAnglesRight className={"txt-yellow me-2"} size={12} />
              Contact Us
            </Link>
          </Stack>
        </Col>
        <Col>
          <h5 className="txt-white poppins-semiBold mb-4">Contact Us</h5>
          <Stack gap={"3"} className="poppins-regular">
            <Link className="footer-link-text">
              <FaLocationDot className={"txt-yellow me-2"} size={16} />
              West Palm Beach Fort Lauderdale Miami, Florida
            </Link>
            <a
              className="footer-link"
              href={emailForDialer}
            >
              <MdEmail className={"txt-yellow me-2"} size={16} />
              {showEmail}
            </a>
            <a className="footer-link" href={phoneForDialer}>
              <FaPhone className={"txt-yellow me-2"} size={16} />
              {showPhone}
            </a>
            {/* <InputGroup size="sm" className=" my-3">
              <Form.Group
                className="poppins-Regular"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  size="sm"
                  aria-label="Email Address"
                  className="placeholderStyle poppins-Regular"
                  type="email"
                  placeholder="Email Address"
                  style={{
                    borderTopLeftRadius: "10",
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0",
                    borderBottomLeftRadius: "10",
                  }}
                  required
                  onChange={(event) => {
                    // setEmail(event.target.value);
                  }}
                />
              </Form.Group>

              <Button
                type="submit"
                size="sm"
                variant="warning"
                className="poppins-Regular"
                style={{
                  borderTopRightRadius: "10",
                  borderBottomRightRadius: "10",
                }}
                onClick={() => {
                  // handleSubmit();
                }}
              >
                Subscribe
              </Button>
            </InputGroup> */}
          </Stack>
        </Col>
      </Row>
      <p className="bg-dark-blue txt-white text-center m-0 py-2 footer-link-text">
        © CORNWALL SECURITY GROUP LLC - All rights reserved
      </p>
    </Container>
  );
};

export default Footer;
